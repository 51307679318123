import React, { Component } from "react";
import { Link, Route } from "react-router-dom";
import HomePage from "./index/";
import ResumePage from "./resume";
import ProjectsPage from "./projects";
import { Layout, Menu, Breadcrumb, Avatar, Image} from "antd";
import {
  HomeOutlined,
  FileOutlined,
  UnorderedListOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
} from "@ant-design/icons";
const { Header, Content, Footer, Sider } = Layout;


class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current: "/",
      collapsed: false,
      visible: false,
      avatar: "https://www.yaocheng.tech/img/avatar.jpg",
    };
    this.handleInputChange = this.handleClick.bind(this);
  }

  handleClick = (e) => {
    console.log("click ", e);
    this.setState({
      current: e.key,
    });
  };

  onCollapse = (collapsed) => {
    console.log(collapsed);
    this.setState({ collapsed: collapsed });
  };

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  render() {
    const { collapsed, avatar } = this.state;

    return (
      <Layout style={{ minHeight: "100vh" }}>
        <Sider collapsible collapsed={collapsed} onCollapse={this.onCollapse}>
          <div className="logo" />
          <Menu
            onClick={this.handleClick}
            theme="dark"
            defaultSelectedKeys={["1"]}
            mode="inline"
          >
            <Menu.Item key="/" icon={<HomeOutlined />}>
              <Link to="/">Home</Link>
            </Menu.Item>
            <Menu.Item key="/resume" icon={<FileOutlined />}>
              <Link to="/resume">Resume</Link>
            </Menu.Item>
            <Menu.Item key="/projects" icon={<UnorderedListOutlined />}>
              <Link to="/projects">Projects</Link>
            </Menu.Item>
          </Menu>
        </Sider>
        <Layout className="site-layout">
          <Header className="site-layout-background" style={{ padding: 0 }}>
            {React.createElement(
              this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
              {
                className: "trigger",
                onClick: this.toggle,
              }
            )}
            <div style={{ lineHeight: "64px", float: "right", marginRight: "16px"}}>
            <Avatar src={<Image src={avatar} />} />
            </div>
          </Header>
          <Content style={{ margin: "0 16px" }}>
            <Breadcrumb style={{ margin: "16px 0" }}>
              <Breadcrumb.Item>Home</Breadcrumb.Item>
              {/* <Breadcrumb.Item>List</Breadcrumb.Item> */}
              <Breadcrumb.Item>
                {this.props.location.pathname.replace("/", "")}
              </Breadcrumb.Item>
            </Breadcrumb>
            <div style={{ padding: 24, minHeight: 360 }}>
              <Route exact path="/" component={HomePage} />
              <Route path="/resume" component={ResumePage} />
              <Route path="/projects" component={ProjectsPage} />
            </div>
          </Content>
          <Footer style={{ textAlign: "center" }}>
            - I have a bottom line, powered by Ant Design -{" "}
          </Footer>
        </Layout>
      </Layout>
    );
  }
}

export default App;
