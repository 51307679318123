import React, { Component } from "react";
import { Divider, Tag, Card, Col, Row, Avatar, Image } from "antd";
import resumeData from "../../data/resume.json";
import {
  LaptopOutlined,
  PhoneOutlined,
  MailOutlined,
  EnvironmentOutlined,
} from "@ant-design/icons";

const { name, label, phone, email, location, summary } = resumeData.basics;
const { skills, languages } = resumeData;

const skillsView = skills.map((section) => {
  const { keywords, name } = section;
  return (
    <div>
      <b>{name}: </b>
      {keywords.map((tag) => (
        <Tag key={tag}>{tag}</Tag>
      ))}
    </div>
  );
});

const languagesView = languages.map((item) => {
  const { language, fluency } = item;

  return (
    <div>
      <b>{language}: </b>
      <i>{fluency}</i>
    </div>
  );
});

class Home extends Component {
  render() {
    return (
      <Row gutter={24}>
        <Col lg={7} md={24}>
          <Card bordered={false} style={{ marginBottom: 24 }}>
            <div style={{ marginBottom: 2 + "em", textAlign: "center" }}>
              <Avatar
                size={64}
                src={
                  <Image
                    alt="YC"
                    src="https://www.yaocheng.tech/img/avatar.jpg"
                  />
                }
              />
              <h1>{name}</h1>
            </div>
            <div>
              <p>
                <LaptopOutlined style={{ marginRight: 0.5 + "em" }} />
                {label}
              </p>
              <p>
                <PhoneOutlined style={{ marginRight: 0.5 + "em" }} />
                {phone}
              </p>
              <p>
                <MailOutlined style={{ marginRight: 0.5 + "em" }} />
                {email}
              </p>
              <p>
                <EnvironmentOutlined style={{ marginRight: 0.5 + "em" }} />
                {location.address}
              </p>
            </div>
          </Card>
        </Col>
        <Col lg={17} md={24}>
          <Card bordered={false}>
            <Divider orientation="left">Summary</Divider>
            <p>{summary}</p>
            <Divider orientation="left">Skills</Divider>
            {skillsView}
            <Divider orientation="left">Languages</Divider>
            {languagesView}
          </Card>
        </Col>
      </Row>
    );
  }
}

export default Home;
