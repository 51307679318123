import React from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import App from './views/App'

const Root = () => (
  <Router>
    <Route path="/:filter?" component={App} />
  </Router>
)

export default Root;