import React, { Component } from "react";
import { List, Card } from "antd";

const projectsData = [
  {
    title: "Budget-JS",
    cover: "https://www.yaocheng.tech/img/budgetJsCover.png",
    description: "Study from Udemy.",
    date: "2019-02-27",
    preview: "https://budget-js.yaocheng.tech",
  },
  {
    title: "Omnifood",
    cover: "https://www.yaocheng.tech/img/omnifoodCover.png",
    description: "Study from Udemy.",
    date: "2019-02-17",
    preview: "https://omnifood.yaocheng.tech",
  },
];

class Projects extends Component {
  render() {
    return (
      <List
        rowKey="id"
        grid={{ gutter: 24, xxl: 3, xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}
        dataSource={projectsData}
        renderItem={(item) => (
          <List.Item>
            <Card
              hoverable
              cover={<img alt={item.title} src={item.cover} />}
              title={item.title}
            >
              <Card.Meta description={item.description} />
              <p>
                <a
                  href={item.preview}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Preview
                </a>
              </p>
            </Card>
          </List.Item>
        )}
      />
    );
  }
}

export default Projects;
