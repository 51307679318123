import React, { Component } from "react";
import { Card, Col, Row, Image } from "antd";

class Resume extends Component {
  render() {
    return (
      <Row gutter={[24, 24]}>
        <Col xl={12} lg={24} md={24} sm={24} xs={24}>
          <Card
            style={{ marginBottom: 24 }}
            title="Theme: elegant (by Mudassir)"
            bordered={false}
            extra={
              <a
                href="https://registry.jsonresume.org/Yao-C"
                target="_blank"
                rel="noopener noreferrer"
              >
                Preview
              </a>
            }
            bodyStyle={{ padding: 0 }}
          >
            <div style={{ marginTop: 0.5 + "em", textAlign: "center" }}>
              <Image
                width={250}
                src="https://www.yaocheng.tech/resume/elegant.png"
              />
            </div>
          </Card>
        </Col>
        <Col xl={12} lg={24} md={24} sm={24} xs={24}>
          <Card
            style={{ marginBottom: 24 }}
            title="Theme: spartan (by Francesco)"
            bordered={false}
            extra={
              <a
                href="https://www.yaocheng.tech/resume/themes/stackoverflow.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Preview
              </a>
            }
            bodyStyle={{ padding: 0 }}
          >
            <div style={{ marginTop: 0.5 + "em", textAlign: "center" }}>
              <Image
                width={250}
                src="https://www.yaocheng.tech/resume/stackoverflow.png"
              />
            </div>
          </Card>
        </Col>
      </Row>
    );
  }
}

export default Resume;
